.after-sel-sec {
    margin: auto;
    width: 600px;
}
.head-titl-unset {
    align-items: unset;
    max-width: 64%;
    max-height: 37%;
}

.apim-pop-button-w {
    display: inline-block;
    text-align: right;
    width: 100%;
}

.ser-req-btn {
    width: 600px;
    margin: 40px auto 0 auto;
    display: block;
}

.btn-disable {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
}

.dropdown-w-info {
    display: flex;
}
.width-full {
    width: 100%;
}

.required-field-op {
    float: right;
    font-size: 11px;
    line-height: 13px;
    color: #a2a2a2;
    margin: 7px 0px 0px;
    padding: 0px;
}
.info-icon-w {
    text-decoration: none;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #ccc;
    border-radius: 9px;
}

.floating-label {
    top: -20px;
    bottom: 10px;
    left: 0;
    font-size: 11px;
    opacity: 1;
    font-size: 16px;
    line-height: 19px;
    color: #909090;
    font-weight: normal;
}
.custom-input-wrapp {
    position: relative;
}
.input-info-label {
    color: #8b8b8b;
}
.inputText {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    line-height: 24px;
    border-bottom: 1px solid #707070;
    width: 100%;
    padding: 9px;
}

.dotted {border: 2px dotted black;}
.solid {border: 2px solid black; position: absolute;}
 .box{
        width: 20%;
        height: 12%;            
        position: absolute;
        top: 17%;
        left: 12%;
        background:white;
        
    }
  
.sec {
    border: 1px dashed #ccc;
    border-radius: 10px;
    position: relative;
    min-height: 100px;
    margin: 62px 0 0 0;
    padding: 26px 24px 0 25px;
}
.head-titlw-w {
    font-weight: bold;
    letter-spacing: .3px;
    position: absolute;
    top: -17px;
    left: 18px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 100px;
    font-size: 19px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
}
.sec-title {
    margin: 0;
}
.sec-inner-wrap {
    margin: 16px 0 0 0;
}
.serv-req {
    width: 550px;
    margin: auto;
}



.hide-content {
    display: none !important;
}
.wrapper span {
    padding-bottom: 10px;
}

.right-actions {
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
}
.span {
    padding-bottom: 10px;
}
ul{
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.cust-text-a {
    width: 100%;
    border: 1px solid #0280a7;
    max-height: 93px;
    min-height: 93px;
    padding: 18px 95px 18px 20px;
    margin: 0;
}
.custom-loader {
    position: relative;
    line-height: 1;
    padding: 13.5px 24px;
}
.iconLoader {
    position: relative;
    top: 2px;
}

.form-select {
    cursor: pointer;
    border-style: solid;
}
.select2-selection {
    display: block;
    padding-left: 8px;
    padding-right: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cust-text-a {
    width: 100%;
    border: 1px solid #0280a7;
    max-height: 93px;
    min-height: 93px;
    padding: 18px 95px 18px 20px;
    margin: 0;
}
.right-actions {
    right: 8px;
    top: 8px;
    width: auto;
    height: auto;
}

span {
    padding-bottom: 10px;
}

disabled {
    color: #969595;
    cursor: not-allowed;
}
.clear-icon-ModifyRegDet-company
  {
    position: absolute;
    
    right: 80px;
    top: 50%;
    transform: translateY(-550%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .clear-icon-AddAccRep-company
  {
    
    
    position: absolute;
    right: 57px;
    top: 0%;
    /* transform: translateY(-290%); */
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;

  }
  .clear-icon-ReplaceAccRep-company
  {
    position: absolute;
    
    right: 55px;
    top: 50%;
    transform: translateY(-50%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .clear-icon-Crep-ReplaceAccRep
  {
    position: absolute;
    
    right: 55px;
    top: 50%;
    transform: translateY(-52%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }
  .clear-icon-DelCompanyRecord-company
  {
    position: absolute;
    
    right: 80px;
    top: 50%;
    transform: translateY(-160%);
    color: #2d32aa;
    font-size: 1.5rem;
    cursor: pointer;
  }

