.empty-cont-header {
    padding: 55px 0 60px 50;
    padding-top: 55px;
    padding-right: 0px;
    padding-bottom: 60px;
    padding-left: 50px;
}
.main-content{
    padding-top: 45px;
    padding-right: 15px;
    padding-bottom: 50px;
    padding-left: 15px;
    
}
ul{
    list-style-type: none;
    padding-left: 0;
}
.react-tel-input {
    font-family: sans-serif;
    font-size: 10px;
    position: relative;
    width: 100%
    
}

.flag-dropdown{
    height: 35px;
    width: 100px;
}

.--PhoneInputCountrySelectArrow-color{
    color: #2d32aa;
    font-weight: bold;
}
.country-list.li{
    margin: 30px;
}

/* .comm-button-style.primary.btn-disable {
    background: #c9cac8;
    border: 1px solid #c9cac8;
    font-weight: normal;
    cursor: not-allowed;
} */
.comm-button-style.primary{
    background: #2d32aa;
    color: #fff;
}
.comm-button-style.secondary{
    color: #2d32aa;
    background: #fff;
}
comm-button-style {
    border-radius: 40px;
    background: #fff;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;
    min-width: 113px;
    padding: 13.5px 33px;
    border: 1px solid #2d32aa;
    color: #2d32aa;
    margin: 0 10px 0 0;
    transition: all .2s ease-in-out;
}
.comm-button-style.secondary:hover , .comm-button-style.primary:hover{
    background-color: #212861;
    color: white;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    /* pointer-events: none; */
    background-color: #adb5bd;
    border-color: #adb5bd;
    /* opacity: var(--bs-btn-disabled-opacity); */
    cursor: not-allowed;
    
}
.modal-header{
    font-size: 18px;
    line-height: 20px;
    background: rgb(45, 50, 170);
    /* text-transform: capitalize; */
    color: rgb(255, 255, 255);
    font-weight: bold;
    padding: 13px 20px;
}
.modal-content{
    height: 210px;
    width:1500px;
    margin-bottom: 0;
    padding-bottom: 0;
    max-width: none !important;
}
.modal-footer{
   padding-bottom: 12px;
   margin: 0;
}
.my-modal {
    width: 70vw  ;  /* Occupy the 90% of the screen width */
    max-width: 90vw;
} 

div{
    width: 100%
}
h1 {
    display: block;
    font-size: 2em;
    font-weight: bold;
  }
  .join-us-wrap {
    width: 550px;
    margin: auto;
    
}
section {
    background-color: #fff;
}
.ms-inputBox {
    padding: 2px 5px;
}
.contact-num-wrap {
    display: flex;
}
.inputText {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    border-bottom: none;
    width: 100%;
    color: #2d32aa;
    padding: 0;
    font-display: block;
    font-weight: bold;
}
.input-info-label {
    color: #A2A2A2;
    margin-left: 12px;
  }
  .floating-label {
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 1px;
    transition: 0.2s ease all;
    font-size: 20px;
    line-height: 20px;
    color:yellow;
    font-weight: bold;
  }
  

  .required-field-op {
    float: right;
    font-size: 11px;
    line-height: 13px;
    color: #A2A2A2;
    margin: 7px 0 0 0;
    padding: 0;
  }
.signup-elements-wrap
{
    padding: 0;
    margin: 0;
    margin-left: 30px;
}
/* ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
} */
.signup-elements-wrap li {
    margin: 0 0 50px 0;
}
.react-tel-input .form-control{
    width:100%;
    padding-left: 110px;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    color: #2d32aa;
    
}
.inputText .form-control{
    
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
    color: #2d32aa;
    font-size: 20px;
    line-height: 20px;
    
}
.react-tel-input .selected-flag .flag
{
    size: 70px;
}
.react-tel-input .country-list li{
    margin: 0 0 0 0;
}
.react-tel-input .selected-flag {
    width: 100px;
}
.iconLoader {
    &:before {
      background-image: url("../../Assets/Images/spinner.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      display: block;
      text-indent: 9999px;
      overflow: hidden;
      animation: rotatecycle 1s infinite ease;
      width: 24px;
      display: inline-block;
    }

   
  }